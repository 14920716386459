<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import SearchResultsPopover from "./SearchResultsPopover/Component";
import { TITLE_REGEX } from "@/constants"

export default {
  name: "Search",
  components: {
    SearchResultsPopover,
  },
  data() {
    return {
      searchTerm: "",
      debouncedSearchTerm: "",
      isLoadingSearchResults: false,
      isLiveSearchVisible: false,
      searchResultsPopoverStyle: "width: 300px;",
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentTeam",
      "isAuthenticated",
      "isUserSudoing",
      "globalQueryTerm",
    ]),
    showSlashIcon() {
      return !this.isLiveSearchVisible;
    },
    currentContextPage() {
      if (this.$route.path.startsWith("/campaigns")) {
        return "campaigns";
      } else if (this.$route.path.startsWith("/projects")) {
        return "projects";
      }
      return null; // Don't care about other cases
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
        });
        if (this.$refs.search) {
          this.$refs.search.blur();
        }
      },
    },
    globalQueryTerm: {
      immediate: true,
      handler(value) {
        this.searchTerm = value;
        this.debouncedSearchTerm = value;
      },
    },
  },
  methods: {
    ...mapActions([
      "recordUserSearchTerm",
    ]),
    fillInRecentSearchTerm(term) {
      this.searchTerm = term;
      this.doSearch();
    },
    showPopover() {
      this.calculateSearchResultsPopoverWidth();
      this.isLiveSearchVisible = true;
    },
    debounceAssignSearchTerm: _.debounce(function() {
      this.debouncedSearchTerm = this.searchTerm;
    }, 500),
    doSearch() {
      if (!this.isUserSudoing) {
        this.recordUserSearchTerm(this.searchTerm);
      }

      let query = { query: this.searchTerm };

      if (this.currentContextPage) {
        query.searchTab = this.currentContextPage;
      }

      this.$router.push({ name: "search", query });
    },
    clearSearch() {
      this.searchTerm = "";
      this.debouncedSearchTerm = "";
      this.$router.push({ name: "search" });
    },
    handleSearchKeydown(event) {
      if (!this.searchTerm) {
        return;
      }

      if (!this.searchTerm?.match(TITLE_REGEX)) {
        return;
      }

      if (event && event.key === "Enter") {
        this.doSearch();
      } else {
        this.debounceAssignSearchTerm();
      }
    },
    hideLiveSearch(e) {
      if (!e.relatedTarget) {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
        });
      }
    },
    keydownHandler(event) {
      let isMessageEditor = event.target?.className?.match(/ql-editor|el-input__inner|el-select__input|editable|new-value-input|el-textarea__inner/);
      isMessageEditor = isMessageEditor || event.target.formAction?.includes("templates");
      if (event.key === "/" && !isMessageEditor) {
        event.preventDefault();
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      } else if (event.key === "Escape") {
        this.$nextTick(() => {
          this.isLiveSearchVisible = false;
          if (this.$refs.search) {
            this.$refs.search.blur();
          }
        });
      }
    },
    calculateSearchResultsPopoverWidth() {
      this.$nextTick(() => {
        const width = document.getElementById("search-input-container")?.getBoundingClientRect().width || 300;
        const searchResultsPopover = document.getElementsByClassName("el-popper popover-search")[0];
        searchResultsPopover.style.width = `${width}px`;
      });
    },
  },
  created() {
    window.addEventListener("keydown", this.keydownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keydownHandler);
  },
}
</script>

<template>
  <div
    id="search-input-container"
    class="w-100"
    :class="{ active: isLiveSearchVisible }"
  >
    <el-popover
      placement="bottom-start"
      trigger="manual"
      :value="isLiveSearchVisible"
      :visible-arrow="false"
      transition="el-zoom-in-top"
      popper-class="popover-search"
    >
      <SearchResultsPopover
        :visible="isLiveSearchVisible"
        :query-term="debouncedSearchTerm"
        @recent-term-selected="fillInRecentSearchTerm"
        @hide="isLiveSearchVisible = false"
      />

      <el-input
        slot="reference"
        ref="search"
        v-model="searchTerm"
        class="search-input"
        :class="{ active: isLiveSearchVisible }"
        placeholder="Search or jump to..."
        clearable
        :maxlength="75"
        @clear="clearSearch"
        @focus="showPopover"
        @blur="hideLiveSearch"
        @keydown.native="handleSearchKeydown"
      >
        <div v-show="showSlashIcon" id="slash-wrapper" slot="suffix">/</div>
      </el-input>
    </el-popover>
  </div>
</template>

<style lang="scss" scoped>
#search-input-container {
  max-width: 360px;

  ::v-deep .search-input {
    font-size: 14px;

    .el-input__inner {
      background-color: $tf-global-search-background-color;
      color: $tf-font-default;
      padding-left: 16px;
      padding-right: 32px;
    }

    &.active {
      .el-input__inner {
        border-radius: $tf-global-border-radius $tf-global-border-radius 0 0;
        color: $tf-font-default;
        border: none; // No need for the input to have border when active as it's clear it's active
      }

      .el-icon-circle-close {
        display: block;
        color: $tf-color-danger !important;
      }
    }

    .el-icon-circle-close {
      display: none;
    }

    .el-input__suffix {
      right: 16px;

      #slash-wrapper {
        padding: 0px 6px;
        font-size: 10px;
        background-color: $beige-100;
        color: $tf-global-search-slash-color;
        border-radius: $tf-global-border-radius;
      }
    }
  }
}
</style>
