import { mapGetters } from "vuex";
import CampaignMessageValidator from "@/common/CampaignMessageValidator";
import CampaignMessageVariables from "@/common/CampaignMessageVariables";
import MessageEditor from "@/components/MessageEditor/Component";
import CampaignsApi from "@/common/http/CampaignsApi";
import { TYPES } from "@/components/MessageGeneration/constants";

export default {
  name: "CampaignMessageEditor",
  components: {
    MessageEditor,
  },
  props: {
    allowSentence: Boolean,
    allowCalendar: Boolean,
    isSubjectEditable: Boolean,
    showPreview: Boolean,
    useIntroRequestVariables: Boolean,
    useIntroMessageVariables: Boolean,
    allowFirstName: {
      type: Boolean,
      default: true,
    },
    allowCompany: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    isReceipientEditable: {
      type: Boolean,
      default: true,
    },
    messageId: {
      type: Number,
      default: null,
    },
    campaignId: {
      type: Number,
      default: null,
    },
    showSubject: {
      type: Boolean,
      default: true,
    },
    body: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    cc: {
      type: String,
      default: null,
    },
    bcc: {
      type: String,
      default: null,
    },
    senderId: {
      type: [String, Number],
      default: null,
    },
    emailsAfterInitialHaveCalendarVariable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showVariables: {
      type: Boolean,
      default: true,
    },
    interpolateVariables: {
      type: Boolean,
      default: true,
    },
    previewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateSubject = (rule, value, callback) => {
      if (!this.isSubjectEditable) {
        callback();
        return;
      }

      const allowed = this.messageVariablesServiceForSubject.values();
      const validator = new CampaignMessageValidator(allowed);
      let errors = validator.generalSubjectMessageErrors(this.message.subject);
      errors = errors.concat(validator.variableErrors(this.message.subject));

      if (errors.length > 0) {
        errors.forEach((e) => callback(new Error(e)));
      } else {
        callback();
      }
    };

    const validateBody = (rule, value, callback) => {
      const allowed = this.messageVariablesServiceForBody.values();
      const validator = new CampaignMessageValidator(allowed);
      let errors = validator.generalBodyMessageErrors(this.message.body);
      errors = errors.concat(validator.variableErrors(this.message.body));

      if (this.emailsAfterInitialHaveCalendarVariable && !this.allowCalendar) {
        errors = errors.concat("The sender doesn't have a calendar set up. Check Follow-ups or Replies for usage of the 'calendar' variable.");
      }

      if (errors.length > 0) {
        errors.forEach(e => callback(new Error(e)));
      } else {
        callback();
      }
    };

    return {
      messageSaved: false,
      message: {
        allCCs: this.cc ? this.cc.split(",") : [],
        allBCCs: this.bcc ? this.bcc.split(",") : [],
        body: this.body,
        cc: null,
        bcc: null,
        subject: this.subject,
      },
      rules: {
        cc: [{ type: "email", message: "Please input a correct email address", trigger: "blur" }],
        bcc: [{ type: "email", message: "Please input a correct email address", trigger: "blur" }],
        subject: [{ validator: validateSubject, trigger: "blur" }],
        body: [{ validator: validateBody, trigger: "blur" }],
      },
      subjectQuillRef: null,
      isLoadingPreview: false,
      previewContent: null,
      previewSubject: null,
      previewDisclaimer: null,
      isShowingCC: !!this.cc,
      isShowingBCC: !!this.bcc,
    };
  },
  computed: {
    ...mapGetters([
      "currentTeam",
      "currentUser",
      "currentUserSettings",
      "currentCampaign",
      "inAppSourcingSelectedCampaign",
      "inAppSourcingSelectedProject",
      "newCampaign",
      "selectedCandidateInAppSourcing",
    ]),
    showPreviewComputed() {
      return this.previewOnly || this.showPreview;
    },
    previewContentComputed() {
      return this.interpolateVariables ? this.previewContent : this.message.body;
    },
    isCampaignEnabled() {
      return !this.showPreview && this.isReceipientEditable && !this.disabled;
    },
    showEmailInfo() {
      return !this.disabled || this.message.subject;
    },
    isSubjectDisabled() {
      return this.showPreview || this.disabled || !this.isSubjectEditable;
    },
    chatbotEnabled() {
      return this.currentUser.chatbotEnabled && (
        this.type === TYPES.FIRST_MESSAGE ||
        this.type === TYPES.FOLLOW_UPS ||
        this.type === TYPES.AUTO_REPLY_POSITIVE ||
        this.type === TYPES.YOUR_REPLY_POSITIVE ||
        this.type === TYPES.APPROVAL
      );
    },
    messageVariablesServiceForBody() {
      if (this.interpolateVariables) {
        return new CampaignMessageVariables({
          customSentenceEnabled: this.allowSentence,
          calendarEnabled: this.allowCalendar,
          firstNameEnabled: this.allowFirstName,
          companyEnabled: this.allowCompany,
          introRequestVariables: this.useIntroRequestVariables,
          introMessageVariables: this.useIntroMessageVariables,
          chatbotEnabled: this.chatbotEnabled,
          chatbotName: this.currentPlaybook?.name,
        });
      }
      return null;
    },
    messageVariablesServiceForSubject() {
      if (this.interpolateVariables) {
        return new CampaignMessageVariables({
          customSentenceEnabled: false,
          calendarEnabled: this.allowCalendar,
          firstNameEnabled: this.allowFirstName,
          companyEnabled: this.allowCompany,
          introRequestVariables: this.useIntroRequestVariables,
          introMessageVariables: this.useIntroMessageVariables,
        });
      }
      return null;
    },
    isMessageValid() {
      let valid;
      this.$refs.messageForm.validate(v => {
        valid = v;
      });
      return valid;
    },
    isTemplatesSettings() {
      return this.$route.name === "templates-settings";
    },
    playbookId() {
      if (this.isTemplatesSettings) {
        return this.currentUserSettings?.inboundAcceptPlaybookId;
      }
      return (this.currentCampaign?.playbookId ||
        this.newCampaign?.playbookId ||
        this.inAppSourcingSelectedCampaign?.playbookId
      );
    },
    currentPlaybook() {
      if (!this.currentUser.chatbotEnabled) return;

      if (this.playbookId) {
        return this.currentUser.playbookOptions?.find(playbook => playbook.id == this.playbookId);
      }

      return null;
    },
  },
  watch: {
    showPreview: "loadPreviewMessage",
    allowCalendar() {
      this.$refs.messageForm.validate();
    },
    message: {
      deep: true,
      handler(msg) {
        let message = Object.assign({}, msg);
        message.cc = msg.allCCs.join(",");
        message.bcc = msg.allBCCs.join(",");
        message.body = this.message.body;

        if (this.isSubjectEditable) {
          message.subject = this.message.subject;
        }
        delete message.allBCCs;
        delete message.allCCs;
        this.$emit("message-change", { message, isValid: this.isMessageValid });
      },
    },
  },
  methods: {
    handleEmailConfirm(type) {
      this.$refs.messageForm.validateField(type, (invalid) => {
        if (!invalid && this.message[type] && this.message[type] !== "") {
          const list = type === "cc" ? this.message.allCCs : this.message.allBCCs;
          list.push(this.message[type]);
          this.message[type] = null;
        }
      });
    },
    handleEmailRemove(email, type) {
      const list = type === "cc" ? this.message.allCCs : this.message.allBCCs;
      const idx = list.findIndex(e => e === email);
      list.splice(idx, 1);
    },
    saveSubjectRef({ ref }) {
      this.subjectQuillRef = ref;
    },
    async loadPreviewMessage() {
      const useMockData = this.useIntroRequestVariables || this.useIntroMessageVariables;
      if (this.showPreview) {
        this.isLoadingPreview = true;
        const params = {
          senderId: this.senderId,
          message: this.message.body,
          subject: this.message.subject,
          projectId: this.inAppSourcingSelectedProject?.id,
          useMockData,
          tfId: this.$route.query.tfId || this.selectedCandidateInAppSourcing?.tfId,
          profileId: this.$route.query.profileId || this.selectedCandidateInAppSourcing?.profileId,
          playbookId: this.currentPlaybook?.id,
        };

        if (this.$route.name !== "templates-settings") {
          params.campaignId = (this.currentCampaign.id || this.campaignId);
        }

        const { data } = await CampaignsApi.previewMessage(params);

        this.previewContent = data.message;
        this.previewSubject = data.subject;
        this.previewDisclaimer = data.info;
        this.isLoadingPreview = false;
      }
    },
  },
};
