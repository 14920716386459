<template>
  <span class="user-dropdown-menu">
    <el-dropdown
      v-if="showDropdown"
      trigger="click"
      @command="handleCommand"
    >
      <div class="d-flex align-items-center">
        <span class="user-name">
          {{ currentUser.name }}
        </span>

        <img class="chevron" :src="require('@/assets/images/icons/chevron-down.svg')" />
      </div>

      <el-dropdown-menu slot="dropdown" class="user-menu-dropdown">
        <div class="d-flex align-items-center px-12">
          <UserAvatar
            :image-url="currentUser.avatarUrl"
            :full-name="currentUser.name"
            hide-name-label
            size="medium"
          />

          <span class="ml-12">{{ currentUser.email }}</span>
        </div>

        <template v-if="!logoutOnly">
          <el-dropdown-item command="settings-root">
            <img class="item-icon" :src="require('@/assets/images/icons/cog-8-tooth.svg')" />
            <span>Settings</span>
          </el-dropdown-item>

          <el-dropdown-item command="team-management-root">
            <img class="item-icon" :src="require('@/assets/images/icons/user-group.svg')" />
            <span>Team</span>
          </el-dropdown-item>

          <el-dropdown-item v-if="showSubscriptions" command="subscriptions">
            <img class="item-icon" :src="require('@/assets/images/icons/credit-card.svg')" />
            <span>Subscriptions</span>
          </el-dropdown-item>

          <slot name="extra-items" />

          <el-divider class="my-8" />

          <el-dropdown-item v-if="isReferralsEnabled" class="external-link">
            <a :href="employeeAppUrl">
              <img class="item-icon" :src="require('@/assets/images/icons/arrows-right-left.svg')" />
              <span>Go to Employee referrals</span>
            </a>
          </el-dropdown-item>

          <el-divider class="my-8" />

          <el-dropdown-item command="example-campaigns">
            <img class="item-icon" :src="require('@/assets/images/icons/queue-list.svg')" />
            <span>Example campaigns</span>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://support.teamable.com/category/43-common-issues" target="_blank" rel="noopener">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-up-right.svg')" />
              <span>FAQs</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a :href="chromeExtensionLink" target="_blank" rel="noopener">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-up-right.svg')" />
              <span>Chrome extension</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://www.teamable.com/pricing/" target="_blank" rel="noopener">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-up-right.svg')" />
              <span>Planning</span>
            </a>
          </el-dropdown-item>

          <el-dropdown-item class="external-link">
            <a href="https://www.teamable.com/blog/" target="_blank" rel="noopener">
              <img class="item-icon" :src="require('@/assets/images/icons/arrow-up-right.svg')" />
              <span>Blog</span>
            </a>
          </el-dropdown-item>
        </template>

        <template v-else>
          <slot name="extra-items" />
        </template>

        <el-divider class="my-8" />

        <el-dropdown-item command="sign-out">
          <img class="item-icon" :src="require('@/assets/images/icons/arrow-right-start-on-rectangle.svg')" />
          <span>Logout</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { EMPLOYEE_APP_URL, EXTENSION_URL } from "@/common/Env";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "UserMenu",
  components: {
    UserAvatar,
  },
  props: {
    logoutOnly: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      supportIsOpen: false,
      menuRoutes: ["settings", "user-settings", "team-settings", "example-campaigns", "subscriptions"],
    };
  },
  computed: {
    ...mapGetters([
      "currentTeam",
      "currentUser",
      "isReferralsEnabled",
      "isUserSudoing",
    ]),
    userNameLabel() {
      if (this.isUserSudoing) {
        return `Sudoing as ${this.currentUser.name }`;
      }
      return this.currentUser.name;
    },
    employeeAppUrl() {
      return EMPLOYEE_APP_URL;
    },
    chromeExtensionLink() {
      return EXTENSION_URL;
    },
    showSubscriptions() {
      return !!this.currentTeam?.allowPlatformPurchase && this.currentUser.isTeamAdmin;
    },
    showDropdown() {
      return !this.$route.meta.skipAuthRedirect;
    },
  },
  methods: {
    handleCommand(command) {
      this.$router.push({ name: command });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown-menu {
  max-height: calc(100vh - 16px);
  display: flex;
  align-items: center;

  .user-name {
    max-width: 155px;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #help-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $tf-icon-size-mini;
    color: $tf-font-color-primary;
  }
}

.chevron {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.external-link {
  display: flex;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    color: $tf-font-color-regular !important;
    text-decoration: none;
  }

  .external-icon {
    margin-left: 4px;
    color: $tf-font-color-regular;
    font-size: 16px;
  }
}

.el-dropdown-menu__item {
  &:hover {
    color: #0000C3 !important;

    a {
      color: #0000C3 !important;
    }

    .item-icon {
      filter: invert(10%) sepia(93%) saturate(7178%) hue-rotate(246deg) brightness(69%) contrast(138%);
    }
  }
}
</style>
